@import 'material';
@import 'conf';
@import 'main';
@import 'utils';
// @import 'responsive';
@import "@fortawesome/fontawesome-free/css/all.min.css";

a {
    cursor: pointer;
}

:root {
    --bs-primary: #33577F !important;
    --bs-second-primary: #C44D4D !important;
    --bs-third-primary: #a5a5a5 !important;
    --bs-four-primary: #ffc000 !important;
    --bs-text-primary: var(--bs-primary) !important;
    --bs-primary-active: var(--bs-primary) !important;
    --bs-primary-inverse: #fff;
    --bs-app-header-height: 55.86px !important;
    --mdc-filled-text-field-focus-active-indicator-color: #EAEAEA;
    --mdc-filled-text-field-ndicator-color: #EAEAEA;
    --mat-form-field-filled-with-label-container-padding-bottom: 0;
}

.disabled {
    background: lightgrey !important;
}