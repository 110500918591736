// Import Angular Material theming
@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css2?family=Mada:wght@200;300;400;500;600;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Mallanna&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manjari&display=swap');

// Import Angular Material prebuilt theme and other styles
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@ng-select/ng-select/themes/material.theme.css';

// Define custom typography
$custom-typography: mat.m2-define-typography-config($font-family: 'Mada, sans-serif');

// // Apply custom typography
@include mat.all-component-typographies($custom-typography);

// .mat-toolbar.mat-primary {
//     background: rgb(224, 224, 224);
//     color: $primary-btn; // Ensure $primary-btn is defined somewhere in your styles
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
// }