html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Mada';
  overflow: hidden;
  width: 100vw;
}

.gm-style-iw-tc {
  display: none !important;
}

.gm-ui-hover-effect {
  // for the map info close buttun
  display: none !important;
}

.caseSelectOverlay .mat-mdc-select-panel {
  overflow-x: hidden !important;
}

.ng-error {
  .ng-placeholder {
    color: #f44336 !important;
  }
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
  background-color: $primary !important;
  border-color: $primary !important;
}

// .mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
//   background-color: $primary !important;
// }

.btn-submit-main {

  @include linear-gradient(to top right, $primary-btn, $secondory-btn);
  color: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin-top: 0.2%;
  float: left;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background: linear-gradient(to top right, $fonts-color, $fonts-color);
  color: white;
  font-family: Arial;
  font-size: 14px;
  font-weight: 500;

}


.titleMarkClass {
  margin-bottom: 55px;
}

.main-container {
  // position: absolute;
  // top: 100px;
  // padding: 10px 10px 10px 10px;
  // // left: 210px;
  // // width: Calc(100% - 240px);
  // left: 0px;
  // width: Calc(100% - 20px);

  // position: absolute;
  // top: 105px;
  padding: 10px 10px 10px 10px;
  // left: 0px;
  // width: calc(100% - 20px);
  overflow-y: hidden;
  // height: calc(100% - 125px);
}

.main-container-iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  padding: 10px 10px 10px 10px;
}

.cdk-global-scrollblock {
  position: static;
}

.defaultColor {
  color: $primary;
}

.greenColor {
  color: green;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.open-container>* {
  width: 100%;
}

.footer {
  position: absolute;
  font-size: 1em;
  right: 10px;
  bottom: 10px;
  color: $fonts-color;

  a {
    color: $secondory-text;
    text-decoration: none;
  }

  a:hover {
    color: $secondory-text;
    text-decoration: underline;
  }
}

.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $primary !important;
}

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $primary;
}

.myPreviewClass .mat-dialog-container {
  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
}

.notification-badge {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    top: 19px;
    left: 3px;
    background-color: $fonts-color;
    border-radius: 50%;
    z-index: 99;
  }
}


.call-dialog-container .mat-dialog-container {
  /* add your styles */
  padding: 0px !important;
}

.cell-container-main {
  width: 100% !important;
  min-height: 630px !important;
}

.cell-container-day-main {
  width: 100% !important;
  min-height: 630px !important;
}

div.gm-style-iw.gm-style-iw-c {
  padding-right: 12px !important;
  padding-bottom: 12px !important;
}

div.gm-style-iw.gm-style-iw-c div.gm-style-iw-d {
  overflow: hidden !important;
}

.searchFieldsBtn {
  float: right;
  margin-left: 5px;
}

.searchFields {
  float: right;
  margin-left: 5px;
  width: 15vw;
}

.visible-sm {
  display: none;
}

@media screen and (max-width: 768px) {
  .visible-sm {
    display: block;
  }

  .hide-sm {
    display: none !important;
  }

  .searchFields {
    margin-bottom: 3px;
    margin-left: 0px;
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .main-container {
    // position: absolute;
    // left: 0px;
    // top: 90px;
    padding: 10px 10px 10px 10px;
    width: calc(100% - 15px);
  }

}

@media print {
  .no-print {
    display: none !important;
  }

  .main-container {
    overflow-y: hidden !important;
    height: auto !important;
  }

  body {
    overflow: auto !important;
    height: auto !important;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.body-container {
  flex: 1 1 auto;
  overflow-y: auto;
}