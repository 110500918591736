@import './color-schemes/_default';
// @import './color-schemes/_dark';
// @import './color-schemes/_lightNew';
@import './mixin';


::ng-deep .tileData .mdc-form-field {
    width: 100%;
}

::ng-deep .tileData .mdc-label {
    width: 100%;
}

::ng-deep .tileData .mat-mdc-checkbox {
    width: 100%;
}

::ng-deep .mdc-dialog__surface {
    padding: 10px;
}

::ng-deep .tileName {
    text-decoration: none !important;
    color: inherit !important;
}

::ng-deep .tileListData .mat-icon {
    color: $primary;
}

::ng-deep .tileListData .mdc-list-item__primary-text,
::ng-deep .autocomplete-option .mdc-list-item__primary-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.tabs-1 .material-icons {
    top: -5px !important;
}